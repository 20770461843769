import PoMap from "./PoMap";

export default class Search {
    constructor() {
        this.PoMap = new PoMap();
        this.searchVars = {};
        this.searchVars.previousInput = "";

        this.searchInputText = document.querySelector("#searchInputText");
        this.searchInputText.addEventListener("input", () => {
            this.processChange();
        });

        this.searchClear = document.querySelector("#searchInputClear");
        this.searchClear.addEventListener("click", () => {
            this.clearSearch();
        });

        this.searchResult = document.querySelector("#searchResult");
    }

    debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    processChange = this.debounce(() => this.handleInput());

    handleInput() {
        if (this.searchInputText.value === "") {
            if (this.searchResult.classList.contains("show"))
                this.searchResult.classList.remove("show");
        } else {
            if (this.searchResult.classList.contains("show") === false)
                this.searchResult.classList.add("show");

            if (
                this.searchInputText.value.toLowerCase().trim() !==
                this.searchVars.previousInput.toLowerCase().trim()
            ) {
                this.searchVars.previousInput = this.searchInputText.value;
                this.search(
                    searchInputText.value,
                    this.updateSearch.bind(this)
                );
            }
        }
    }

    async search(query, callback) {
        let response = "";
        try {
            response = await fetch(
                `/umbraco/api/constituencydata/getconstituencynames?query=${query}`,
                {}
            );
        } catch (err) {
            console.log(`Failed to search`);
            return;
        }

        let json = "";
        try {
            json = await response.json();
        } catch (err) {
            console.log(`Invalid response received from search`);
            return;
        }

        let searchList = "";
        try {
            searchList = JSON.parse(JSON.stringify(json));
        } catch (err) {
            console.log(`Malformed JSON received from search`);
            return;
        }

        if (typeof callback !== "undefined") {
            callback(searchList);
        }
    }

    updateSearch(searchList) {
        if (searchList.searchItems === null) {
            this.searchResult.innerHTML = "";
            return;
        }

        this.searchResult = document.querySelector("#searchResult");

        let result = "<div class='container'><div>";
        for (const element of searchList) {
            result += `<div>
                    <a class="search-item" href='#'>
                        <img src="/assets/icons/icon-pin.png"/>
                        <div>
                            <h3>${element}</h3>
                            <span style="display:none;">${element}</span>
                        </div>
                    </a>
                    <hr style="height: 1px;border: none;background-color: rgba(0,0,0,0.11);"/>
                </div>`;
        }
        result += "</div></div>";
        this.searchResult.innerHTML = result;

        this.searchItemElements = searchResult.querySelectorAll(".search-item");
        this.searchItemElements.forEach((searchItem) => {
            searchItem.addEventListener("click", (event) => {
                this.submitSearch(event, searchItem);
            });
        });
    }

    clearSearch() {
        this.toggleSearchItems();
        this.searchInputText.value = "";
        this.searchResult.innerHTML = "";
        this.searchVars.previousInput = "";
    }

    toggleSearchItems() {
        if (this.searchResult.classList.contains("show")) {
            this.searchResult.classList.remove("show");
        }
    }

    async getConstituency(query, callback) {
        let response = "";
        try {
            response = await fetch(
                `/umbraco/api/constituencydata/getconstituency?query=${query}`,
                {}
            );
        } catch (err) {
            console.log(`Failed to get constituency coodinates`);
            return;
        }

        let json = "";
        try {
            json = await response.json();
        } catch (err) {
            console.log(
                `Invalid response received from get constituency coodinates`
            );
            return;
        }

        let constituency = {};
        try {
            constituency = JSON.parse(JSON.stringify(json));
        } catch (err) {
            console.log(
                `Malformed JSON received from get constituency coodinates`
            );
            return;
        }

        if (typeof callback !== "undefined") {
            callback(constituency);
        }
    }

    submitSearch(event, element) {
        event.preventDefault();
        this.toggleSearchItems();
        const searchItem = element;
        const nameEl = searchItem.querySelector("h3");

        this.getConstituency(
            nameEl.innerHTML,
            this.PoMap.displayConstituency.bind(this)
        );
    }
}
