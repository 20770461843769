import BingMaps from "ol/source/BingMaps";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { toLonLat, fromLonLat } from "ol/proj";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Fill from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";
import Overlay from 'ol/Overlay';
import Search from "./search";

export default class PoMap {
    static instance;
    constructor(element) {
        if (PoMap.instance) {
            return PoMap.instance;
        }
        PoMap.instance = this;
        this.element = document.querySelector(element);
        if (!this.element) return;

        this.search = new Search();

        this.mapData = {};
        this.mapData.features = [];
        this.mapData.layer = [];
        this.mapData.styles = [
            "RoadOnDemand",
            "Aerial",
            "AerialWithLabelsOnDemand",
            "CanvasLight",
            "CanvasDark",
            "OrdnanceSurvey",
        ];
        this.mapData.layers = [];

        this.generateLayers();
        this.generateMap();

        this.layerSelect = document.querySelector("#layer-select");
        this.layerSelect.addEventListener("change", () => {
            this.updateLayers();
        });
        this.updateLayers();

        this.generateOverlay();

        //this.alertLatLong();
    }

    generateLayers() {
        let i, ii;
        for (i = 0, ii = this.mapData.styles.length; i < ii; ++i) {
            this.mapData.layers.push(
                new TileLayer({
                    visible: false,
                    preload: Infinity,
                    source: new BingMaps({
                        key:
                            "AkTUSfBbeVSkgyYdHT5OCO7jZOMuHuueys67uT4pILaFMxD92uxXwmSptQhfmJsw" +
                            "&c4w=1&cstl=rd&src=h&st=ar|fc:F9F9F9_wt|fc:CCEDF3_tr|fc:F9F9F9;sc:F9F9F9_ard|fc:F9F9F9;sc:F9F9F9_rd|fc:F9F9F9;sc:F9F9F9_st|fc:F9F9F9;sc:F9F9F9_g|lc:F9F9F9",
                        // + '&c4w=1&cstl=rd&src=h&st=ar|fc:b5db81_wt|fc:a3ccff_tr|fc:50a964f4;sc:50a964f4_ard|fc:ffffff;sc:ffffff_rd|fc:50fed89d;sc:50eab671_st|fc:ffffff;sc:ffffff_g|lc:dfdfdf'
                        imagerySet: this.mapData.styles[i],
                        maxZoom: 19,
                    }),
                })
            );
        }
    }

    generateMap() {
        this.mapData.map = new Map({
            layers: this.mapData.layers,
            target: "map",
            view: new View({
                center: fromLonLat([-2.03904908895491, 54.10972074038099]),
                //center: fromLonLat([-1.965, 52.449]),
                zoom: 6,
                // center: fromLonLat([-4.430019777728629,54.73341297101521]),
            }),
        });
    }

    updateLayers() {
        const style = this.layerSelect.value;
        for (let i = 0, ii = this.mapData.layers.length; i < ii; ++i) {
            this.mapData.layers[i].setVisible(this.mapData.styles[i] === style);
        }
    }

    generateOverlay() {
        // Popup showing the position the user clicked
        this.mapData.popup = new Overlay({
            element: document.getElementById('popup'),
        });
        this.mapData.map.addOverlay(this.mapData.popup);
    }
    
    displayConstituency(constituency) {
        console.log(constituency);
        // console.log(`Long: ${constituency.coordLong} | Lat: ${constituency.coordLat}`);
        const feature = new Feature({
            name: constituency.consName,
            geometry: new Point(fromLonLat([constituency.coordLat, constituency.coordLong])),
        });
        feature.setStyle([
            new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    opacity: 0,
                    src: `/assets/icons/icon-fill.png`,
                }),
            }),
        ]);

        if (this.PoMap.mapData.features.length > 0) {
            this.PoMap.mapData.features = [];
            this.PoMap.mapData.map.removeLayer(this.PoMap.mapData.layer);
        }

        this.PoMap.mapData.features.push(feature);

        this.PoMap.mapData.layer = new VectorLayer({
            title: "Constituencies",
            source: new VectorSource({
                features: this.PoMap.mapData.features,
            }),
            style: new Style(),
        });

        this.PoMap.mapData.map.addLayer(this.PoMap.mapData.layer);

        this.PoMap.mapData.map.getView().setCenter(fromLonLat([constituency.coordLat, constituency.coordLong]));
        this.PoMap.mapData.map.getView().setZoom(8);

        const element = this.PoMap.mapData.popup.getElement();

        this.PoMap.mapData.popup.setPosition(fromLonLat([constituency.coordLat, constituency.coordLong]));
        let popover = bootstrap.Popover.getInstance(element);
        if (popover) {
            popover.dispose();
        }
        const content = `
            <div>
                <div>
                    <img src="/assets/icons/icon-office.png" />
                    <p>MP: ${constituency.mpName}</p>
                </div>                
                <div>
                <img src="/assets/icons/icon-office.png" />
                    <p>Local contact: ${constituency.contactName}</p>
                </div>
                <a id="popupSubmit" href="/all-constituencies/constituency/?c=${constituency.consName}">FIND OUT MORE</a>
            </div>
        `;
        popover = new bootstrap.Popover(element, {
            animation: false,
            container: element,
            content: content,
            html: true,
            placement: "top",
            title: constituency.consName,
        });
        popover.show();
    }

    alertLatLong() {
        this.mapData.map.on("click", function (event) {
            console.log(event.coordinate);
            var latLon = toLonLat(event.coordinate);
            alert("[" + latLon[0] + "," + latLon[1] + "]");
        });
    }
}
